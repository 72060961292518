@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;500;600;700;800;900;1000&display=swap");

@font-face {
  font-family: "MarkPro";
  src:
    url("../fonts/markpro/MarkPro-ExtraLight.woff2") format("woff2"),
    url("../fonts/markpro/MarkPro-ExtraLight.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "MarkPro";
  src:
    url("../fonts/markpro/MarkPro-Bold.woff2") format("woff2"),
    url("../fonts/markpro/MarkPro-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "MarkPro";
  src:
    url("../fonts/markpro/MarkPro.woff2") format("woff2"),
    url("../fonts/markpro/MarkPro.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "MarkPro";
  src:
    url("../fonts/markpro/MarkPro-Medium.woff2") format("woff2"),
    url("../fonts/markpro/MarkPro-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "MarkPro";
  src:
    url("../fonts/markpro/MarkPro-Black.woff2") format("woff2"),
    url("../fonts/markpro/MarkPro-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "MarkPro";
  src:
    url("../fonts/markpro/MarkPro-Heavy.woff2") format("woff2"),
    url("../fonts/markpro/MarkPro-Heavy.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Cairo";
  src:
    url("../fonts/cairo/Cairo-ExtraLight.ttf") format("ttf"),
    url("../fonts/cairo/Cairo-ExtraLight.ttf") format("ttf");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Cairo";
  src:
    url("../fonts/cairo/Cairo-Light.ttf") format("ttf"),
    url("../fonts/cairo/Cairo-Light.ttf") format("ttf");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Cairo";
  src:
    url("../fonts/cairo/Cairo-Regular.ttf") format("ttf"),
    url("../fonts/cairo/Cairo-Regular.ttf") format("ttf");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Cairo";
  src:
    url("../fonts/cairo/Cairo-Medium.ttf") format("ttf"),
    url("../fonts/cairo/Cairo-Medium.ttf") format("ttf");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Cairo";
  src:
    url("../fonts/cairo/Cairo-SemiBold.ttf") format("ttf"),
    url("../fonts/cairo/Cairo-SemiBold.ttf") format("ttf");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Cairo";
  src:
    url("../fonts/cairo/Cairo-Bold.ttf") format("ttf"),
    url("../fonts/cairo/Cairo-Bold.ttf") format("ttf");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Cairo";
  src:
    url("../fonts/cairo/Cairo-ExtraBold.ttf") format("ttf"),
    url("../fonts/cairo/Cairo-ExtraBold.ttf") format("ttf");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Cairo";
  src:
    url("../fonts/cairo/Cairo-Black.ttf") format("ttf"),
    url("../fonts/cairo/Cairo-Black.ttf") format("ttf");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

html {
  scroll-behavior: smooth;
}

body {
  font-weight: 400;
  overflow-x: hidden;
  font-display: swap;
  font-family: "MarkPro", Arial !important;
}

body.rtl {
  direction: rtl;
  font-family: "Cairo", Arial !important;
}

a {
  cursor: pointer;
  color: #6f767a;
  transition: 0.3s;
  text-decoration: none;
}

a:hover {
  transition: 0.3s;
  text-decoration: none;
}

a.active {
  color: #ffb03b;
}

ul {
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
  cursor: context-menu;
}

h1,
h2,
h3,
h4,
h5,
h6,
.steppers,
.Toastify__toast-body {
  font-family: "MarkPro", sans-serif !important;
}

body.rtl h1,
body.rtl h2,
body.rtl h3,
body.rtl h4,
body.rtl h5,
body.rtl h6,
body.rtl .steppers,
.rtl .Toastify__toast-body,
body.rtl a,
body.rtl button,
body.rtl span,
body.rtl p {
  font-family: "Cairo", sans-serif !important;
}

input[type="submit"]:disabled,
button[type="submit"]:disabled,
.ah-btn.primary-btn.disabled {
  opacity: 0.6;
  pointer-events: none;
  border-color: #ccc !important;
  background-color: #ccc !important;
}

.modal-backdrop {
  width: 100% !important;
  height: 100% !important;
}

/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/

.back-to-top {
  position: fixed;
  left: 15px;
  cursor: pointer;
  bottom: 15px;
  z-index: 996;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  transition: all 0.4s;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffb71b;
}

.back-to-top svg {
  color: #fff;
  line-height: 0;
}

.back-to-top:hover {
  background-color: #05c3de;
}

.back-to-top.active {
  visibility: visible;
  opacity: 1;
}

.rtl .back-to-top {
  left: 15px;
  right: auto;
}

/*--------------------------------------------------------------
# Top Bar
--------------------------------------------------------------*/

#topbar {
  z-index: 999;
  height: 40px;
  font-size: 14px;
  padding: 0 60px;
  transition: all 0.5s;
  justify-content: flex-end;
  background-color: #fafafa;
  direction: ltr !important;
}

.rtl #topbar {
  direction: rtl !important;
}

#topbar>a,
#topbar>div {
  margin: 0 8px;
}

body.rtl #topbar .lang-box {
  margin: 0 8px 0 0;
}

#topbar.topbar-transparent {
  background: transparent;
}

#topbar svg {
  cursor: pointer;
  color: #6f767a;
}

#topbar .whatsapp-icon {
  line-height: 16px;
}

#topbar .whatsapp-icon svg {
  font-size: 18px;
  color: #25d366;
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/

.headlines {
  display: flex;
  min-height: 85px;
  padding: 10px 60px;
  align-items: center;
  background-color: #05c3de;
}

.headlines .carousel-item>div {
  display: flex;
  min-height: 65px;
  align-items: center;
}

.headlines .rating svg {
  color: gold;
  font-size: 12px;
  margin: 6px 2px 0;
}

.headlines .brand-logo img {
  width: 140px;
  max-height: 38px;
}

.headlines .story {
  color: #fff;
  padding: 0 0 0 20px;
}

.rtl .headlines .story {
  padding: 0 20px 0 0;
}

.headlines .story p {
  margin: 0;
  font-size: 14px;
  font-weight: 600;
}

.headlines .story span {
  font-size: 14px;
  line-height: 18px;
  display: inline-block;
}

.headlines .story span a {
  color: #666;
  font-size: 14px;
  text-decoration: underline;
}

.headlines .story span a:hover {
  color: #222 !important;
}

#header {
  top: 40px;
  z-index: 999;
  transition: all 0.5s;
  background-color: #fff !important;
  border-bottom: 2px solid #fafafa;
}

#header.header-scrolled {
  height: 70px;
}

#header.header-scrolled {
  top: 0;
  background: rgba(26, 24, 22, 0.85);
}

#header .logo {
  transform: translateY(-22px);
}

#header .logo img {
  transform: scale(1.2);
}

.logo img {
  margin: 0;
  padding: 0;
  width: 170px;
}

.rtl .logo img {
  width: 160px;
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
.navbar {
  padding: 0;
}

.navbar ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
}

.navbar li {
  cursor: pointer;
  transition: 0.3s;
  text-align: center;
  position: relative;
}

.navbar .nav-link {
  padding: 0;
  height: 90px;
  display: flex;
  color: #444;
  font-size: 18px;
  padding: 0 18px;
  cursor: pointer;
  font-weight: 500;
  align-items: center;
  justify-content: center;
}

.navbar .dropdown-area .dropdown-toggle {
  padding: 0 18px;
}

.navbar li:not(.mb-logo):hover,
.navbar li.true,
.navbar .dropdown-area:hover,
.navbar .dropdown-area.true {
  transition: 0.3s;
  background-color: rgba(31, 193, 218, 0.12);
}

.navbar li:hover .nav-link,
.navbar li.true .nav-link,
.navbar .dropdown.show .dropdown-area .nav-link button,
.navbar .dropdown-area:hover .dropdown-toggle,
a:not(.primary-btn):hover,
.navbar .dropdown-area.true .nav-link,
.navbar .dropdown-area .dropdown-item.true {
  transition: 0.3s !important;
  color: #05c3de !important;
}

.navbar .nav-link:focus {
  color: #6f767a;
}

.navbar .nav-link svg {
  opacity: 0;
  margin: auto;
  display: block;
  color: #05c3de;
  transition: 0.3s;
  position: absolute;
  max-width: fit-content;
  transform: translateY(18px);
}

.navbar li:hover a svg,
.navbar .dropdown-area:hover .nav-link svg {
  opacity: 1;
  transition: 0.3s;
}

.navbar .dropdown-area .dropdown-toggle {
  color: #444;
  font-size: 18px;
  font-weight: 500;
  background-color: transparent;
}

.rtl .navbar .dropdown-area .dropdown-toggle,
.rtl .navbar .header-menu>a {
  font-size: 18px;
  font-weight: 600;
}

.navbar .dropdown-area .dropdown-toggle::after {
  display: none;
}

.navbar .dropdown-area .dropdown-menu {
  border: 0;
  padding: 0;
  transition: 0.3s;
  max-width: 230px;
  min-width: 230px;
  user-select: none;
  border-radius: 8px;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.06);
}

.navbar .dropdown-area .dropdown-item {
  color: #444;
  font-size: 15px;
  line-height: 18px;
  padding: 12px 20px;
  white-space: break-spaces;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.navbar .offersNewTag {
  color: #fff;
  font-size: 10px;
  padding: 3px 4px 0;
  cursor: pointer;
  border-radius: 5px;
  text-transform: uppercase;
  background-color: #00c0de;
  border: 1px solid #00c0de;
}

.navbar .headerNewTag {
  cursor: pointer;
  margin-left: 5px;
}

body.rtl .navbar .headerNewTag {
  margin-left: 0;
  margin-right: 5px;
}

body.rtl .navbar .dropdown-area .dropdown-item {
  text-align: right;
}

.navbar .dropdown-area .dropdown-item:first-child {
  border-radius: 8px 8px 0 0;
}

.navbar .dropdown-area .dropdown-item:last-child {
  border-radius: 0 0 8px 8px;
}

.navbar .dropdown-area .dropdown-item:hover,
.navbar .dropdown-area .dropdown-item.true {
  background-color: rgba(31, 193, 218, 0.1);
}

.mobile-nav-close {
  top: 70px;
  right: 20px;
  width: 15px;
  padding: 2px;
  height: 15px;
  display: flex;
  color: #fff;
  position: absolute;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  background-color: #1b2a36;
}

.rtl .mobile-nav-close {
  right: auto;
  left: 20px;
}

.navbar .dropdown ul {
  margin: 0;
  z-index: 99;
  opacity: 0;
  display: none;
  padding: 10px 0;
  transition: 0.3s;
  border-radius: 4px;
  position: absolute;
  background-color: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
}

.navbar .dropdown ul li {
  min-width: 200px;
}

.navbar .dropdown ul a {
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 400;
  color: #433f39;
}

.navbar .dropdown ul a i {
  font-size: 12px;
}

.navbar .dropdown ul a:hover,
.navbar .dropdown ul .active:hover,
.navbar .dropdown ul li:hover>a {
  color: #ffb03b;
}

.navbar .dropdown:hover>ul {
  opacity: 1;
  top: 100%;
  display: block;
}

.navbar .dropdown .dropdown ul {
  top: 0;
  left: calc(100% - 30px);
  display: none;
}

.navbar .dropdown .dropdown:hover>ul {
  top: 0;
  opacity: 1;
  left: 100%;
  display: block;
}

@media (max-width: 1366px) {
  .navbar .dropdown .dropdown ul {
    left: -90%;
  }

  .navbar .dropdown .dropdown:hover>ul {
    left: -100%;
  }
}

/* Mobile Navigation  */
.mobile-nav-toggle {
  color: #05c3de;
  font-size: 22px;
  cursor: pointer;
  display: none;
  line-height: 0;
}

@media (max-width: 991px) {
  .navbar ul {
    display: none;
  }
}

.navbar-mobile {
  position: fixed !important;
  overflow: hidden;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(39, 37, 34, 0.9);
  transition: 0.3s;
  z-index: 0;
}

.navbar-mobile ul {
  top: 65px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  display: block;
  padding: 25px 0;
  transition: 0.3s;
  overflow-y: auto;
  max-height: 75vh;
  border-radius: 8px;
  position: absolute;
  background-color: #fff;
}

.navbar-mobile a {
  padding: 10px 20px;
  font-size: 15px;
  color: #433f39;
}

.navbar-mobile a:hover,
.navbar-mobile .active,
.navbar-mobile li:hover>a {
  color: #ffb03b;
}

.navbar-mobile .getstarted {
  margin: 15px;
}

.navbar-mobile .dropdown ul {
  height: 0;
  opacity: 1;
  z-index: 99;
  display: none;
  overflow: hidden;
  margin: 10px 20px;
  visibility: visible;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
}

.navbar-mobile .dropdown ul li {
  min-width: 200px;
}

.navbar-mobile .dropdown ul a {
  padding: 10px 20px;
}

.navbar-mobile .dropdown ul a i {
  font-size: 12px;
}

.navbar-mobile .dropdown ul a:hover,
.navbar-mobile .dropdown ul .active:hover,
.navbar-mobile .dropdown ul li:hover>a {
  color: #ffb03b;
}

.navbar-mobile .dropdown>.dropdown-active {
  display: block;
}

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#hero {
  padding: 0;
  height: 650px;
  overflow: hidden;
}

#hero .carousel-item {
  width: 100%;
  /* height: 650px; */
  aspect-ratio: 16 / 6;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

#hero .carousel-item::before {
  content: "";
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1;
  position: absolute;
  background-image: linear-gradient(180deg, transparent 45%, #222629 100%);
}

#hero .carousel-container {
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  position: absolute;
  align-items: flex-end;
  justify-content: center;
}

body.rtl #hero .carousel-container {
  left: 0;
}

#hero .content-holder {
  left: 50%;
  bottom: 0;
  z-index: 2;
  width: 100%;
  position: absolute;
  transform: translate(-50%, 0);
}

#hero h2 span {
  color: #ffb03b;
}

#hero .carousel-control-next-icon,
#hero .carousel-control-prev-icon {
  opacity: 1;
}

#hero .carousel-control-next svg,
#hero .carousel-control-prev svg {
  display: flex;
  line-height: 0;
  font-size: 28px;
  transition: 0.3s;
  border-radius: 50px;
  align-items: center;
  justify-content: center;
  background-color: #000;
  color: #fff;
  padding: 8px 13px;
}

#hero .carousel-control-next i:hover,
#hero .carousel-control-prev i:hover {
  color: #fff;
}

#hero .carousel-indicators li,
#hero .carousel-indicators button {
  cursor: pointer;
}

#hero .btn-menu,
#hero .btn-book {
  font-weight: 600;
  font-size: 13px;
  letter-spacing: 1px;
  text-transform: uppercase;
  display: inline-block;
  padding: 12px 30px;
  border-radius: 50px;
  transition: 0.5s;
  line-height: 1;
  margin: 0 10px;
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
  color: #fff;
  border: 2px solid #ffb03b;
}

#hero .btn-menu:hover,
#hero .btn-book:hover {
  background: #ffb03b;
  color: #fff;
}

@media (min-width: 1024px) {

  #hero .carousel-control-prev,
  #hero .carousel-control-next {
    width: 5%;
  }
}

section {
  padding: 60px 60px;
}

.section-bg {
  background-color: white;
}

.section-title {
  text-align: center;
  padding-bottom: 30px;
}

.section-title h2 {
  margin: 15px 0 0 0;
  font-size: 32px;
  font-weight: 700;
  color: #5f5950;
}

.section-title h2 span {
  color: #ffb03b;
}

.section-title p {
  margin: 15px auto 0 auto;
  font-weight: 300;
}

@media (min-width: 1024px) {
  .section-title p {
    width: 50%;
  }
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
.web-footer {
  padding: 60px;
  background-color: #f6f6f6;
  border-top: 4px solid #05c3de;
  direction: ltr !important;
}

.web-footer .logo {
  margin-right: 0 !important;
}

body.rtl .web-footer .logo {
  margin-top: 0;
}

.rtl .web-footer {
  direction: rtl !important;
}

.web-footer .main-wrapper .group-label {
  display: block;
  font-size: 20px;
  font-weight: 500;
  color: #303e49;
  margin-bottom: 12px;
}

.rtl .contact-us-box {
  direction: ltr;
  text-align: right;
}

.contact-us-box .number {
  font-size: 18px;
  font-weight: bold;
  color: #00c0de;
  -webkit-transition: all 0.25s;
  -o-transition: all 0.25s;
  transition: all 0.25s;
  margin-top: 5px;
  margin-left: 5px;
  cursor: pointer;
}

.contact-us-box .number:hover {
  color: #303e49;
}

.contact-us-box .fa-phone {
  color: #6c757d !important;
  font-size: 18px;
}

.web-footer .links-wrapper {
  display: flex;
  flex-direction: column;
}

.web-footer a {
  color: #6f767a;
  margin-bottom: 4px;
}

.web-footer .services-wrapper,
.services-btns .services-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.services-btns .services-wrapper {
  width: 75%;
  margin: 0 auto;
  margin-top: 5rem;
}

.services-wrapper .book-apnt-btn {
  border: 0;
  font-size: 14px;
  transition: 0.3s;
  font-weight: 600;
  margin: 5px 10px 5px 0;
  max-width: fit-content;
  min-width: fit-content;
  text-transform: uppercase;
  color: #fff !important;
  border-radius: 0 0 20px 0;
  border: 1px solid #ffb71b;
  background-color: #ffb71b;
  padding: 8px 16px !important;
}

.rtl .services-wrapper .book-apnt-btn {
  margin: 5px 0 5px 10px;
  border-radius: 0 0 0 15px;
}

.services-wrapper .book-apnt-btn:hover {
  transition: 0.3s;
  color: #ffb71b !important;
  background-color: transparent;
}

.services-wrapper .blue-color-btn {
  background-color: #05c3de;
  border: 1px solid #05c3de;
}

.services-wrapper .blue-color-btn:hover {
  color: #05c3de !important;
}

.web-footer .company-wrapper {
  display: flex;
  flex-direction: column;
}

.footer-para-1,
.footer-para-2 {
  color: #707070;
  font-size: 14px;
}

.footer-para-2 {
  width: 80%;
}

.footer-para-2-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.footer-terms-and-conditions {
  cursor: pointer;
  font-weight: 700;
  color: #05c3de !important;
}

.footer-privacy-policy {
  font-weight: 600;
  font-size: 18px;
  color: #303e49 !important;
}

.footer-privacy-policy:hover {
  color: #00c0de !important;
}

.web-footer .footer-social-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 15px;
}

.web-footer .footer-social-wrapper i {
  line-height: 0;
}

.footer-social-wrapper .social-icon {
  cursor: pointer;
  margin-right: 10px;
  background-color: #303e49;
  color: #fff !important;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rtl .footer-social-wrapper .social-icon:first-child {
  margin-right: 0;
}

.footer-social-wrapper .facebook:hover {
  background-color: #3b5998 !important;
  color: #fff !important;
}

.footer-social-wrapper .twitter:hover {
  background-color: #55acee !important;
  color: #fff !important;
}

.footer-social-wrapper .instagram:hover {
  background-color: #962fbf !important;
  color: #fff !important;
}

.footer-social-wrapper .linkedin:hover {
  background-color: #0072b1 !important;
  color: #fff !important;
}

/* ----------------------------
    ACCESSIBILITY PAGE
-------------------------------- */
.accessibility-wrapper,
.privacy-policy-wrapper,
.required-documents-wrapper,
.sitemap-wrapper {
  margin: 0 auto;
  padding: 40px;
  line-height: 1.8;
  background-color: #f3f4f5;
}

.careem-code-wrapper .main-heading,
.accessibility-wrapper .main-heading,
.privacy-policy-wrapper .main-heading,
.required-documents-wrapper .main-heading,
.sitemap-wrapper .main-heading {
  color: #00c0de;
  font-weight: 600;
  margin-bottom: 15px;
}

.lang-box-careem {
  margin: 20px;
  direction: rtl;
}

.rt.lang-box-careem {
  direction: ltr;
}

.accessibility-wrapper h6,
.privacy-policy-wrapper h6 {
  font-weight: 700;
  margin-top: 30px;
}

.terms-main-wrapper a,
.terms-main-wrapper a b {
  color: #05c3de;
}

.terms-main-wrapper ol.alphabetic {
  list-style-type: lower-alpha;
}

.terms-main-wrapper ol li {
  line-height: 22px;
  margin-bottom: 7px;
}

.accessibility-wrapper p,
.privacy-policy-wrapper p,
.required-documents-wrapper p {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  margin-bottom: 10px;
}

.privacy-policy-wrapper ul {
  list-style-type: disc;
}

.privacy-policy-wrapper ul li {
  margin: 7px 0;
  line-height: 20px;
}

.required-documents-wrapper p {
  margin-top: 10px;
}

.accessibility-wrapper ul li,
.required-documents-wrapper .required-documents-list-style-custom li {
  position: relative;
  list-style-type: disc;
}

/* -------------------------------
    REQUIRED DOCUMENTS
---------------------------------- */
.required-documents-wrapper table {
  width: 100% !important;
}

.required-documents-table tr {
  border-top: 1px solid #dee2e6;
}

/* -------------------------------
    SITEMAP
---------------------------------- */
.sitemap-wrapper table {
  width: 100% !important;
}

.sitemap-wrapper table tr {
  border-top: 1.5px solid #dee2e6;
}

.sitemap-wrapper table tr td,
.sitemap-wrapper table tr th {
  line-height: 20px;
  padding: 15px 0 15px 10px !important;
}

.top-bar .tb-right {
  display: flex;
  align-items: center;
}

.ah-btn.primary-btn {
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.5px;
  padding: 10px 25px;
  color: #fff !important;
  border-radius: 0 0 25px 0;
  text-transform: uppercase;
  background-color: #ffb71b;
}

.ah-btn.primary-btn:hover,
.ah-btn.primary-btn:active {
  border-color: #ffb71b;
  color: #ffb71b !important;
  background-color: transparent;
}

body.rtl .ah-btn.primary-btn {
  margin-left: 0;
  border-radius: 0 0px 0px 25px;
}

.ah-btn.primary-btn-roundLeft {
  margin-left: 0px;
  margin-right: 15px;
  border-radius: 15px 0 0 0;
  background-color: #05c3de;
}

.ah-btn.primary-btn-roundLeft:hover {
  color: #05c3de !important;
  border-color: #05c3de;
}

.ah-btn.primary-btn-blueRoundRight {
  padding: 10px 25px;
  border-radius: 0 0 25px 0;
  background-color: #05c3de;
}

.ah-btn.primary-btn-blueRoundLeftBottom {
  padding: 10px 25px;
  border-radius: 0 0 0 25px;
  background-color: #05c3de;
}

.ah-btn.primary-btn-blueRoundRight:hover,
.ah-btn.primary-btn-blueRoundLeftBottom:hover {
  border-color: #05c3de;
  color: #05c3de !important;
  background-color: transparent;
}

body.rtl .ah-btn.primary-btn-roundLeft {
  margin-left: 10px;
  border-radius: 0 15px 0 0;
}

.ah-btn.primary-btn.primary-btn-big {
  margin-left: 0;
  /* font-weight: 800; */
  padding: 12px 30px;
}

.ah-btn.primary-btn.rounded-left {
  margin: 0 15px 0 0;
  border-radius: 0 0 0 25px;
}

.rtl .ah-btn.primary-btn.rounded-left {
  margin: 0 0 0 15px;
  border-radius: 0 0 25px 0;
}

.ah-btn.primary-btn.rounded-left.secondary {
  color: #212529 !important;
  border: 1px solid #dee2e6;
  background-color: #dee2e6;
}

.ah-btn.primary-btn.rounded-left.secondary:hover {
  background-color: transparent;
}

.ah-btn.btn.primary-btn.rounded-right {
  margin: 0 15px 0 0;
}

body.rtl .ah-btn.btn.primary-btn.rounded-right {
  margin: 0 0 0 15px;
}

.resp-bottom-bar-right .bottom-bar-right .rounded-right {
  margin-bottom: 15px;
}

body.rtl .resp-bottom-bar-right .bottom-bar-right .rounded-right {
  margin-right: 15px;
  margin-bottom: 15px;
}

.bottom-bar {
  padding: 0 60px;
}

.bottom-bar .bottom-bar-left {
  width: 100%;
  display: flex;
  padding-left: 15px;
  align-items: center;
}

.bottom-bar .bottom-bar-left #navbar {
  padding: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

body.rtl .bottom-bar .bottom-bar-left {
  padding-left: 0;
  padding-right: 15px;
}

.bottom-bar-right {
  display: flex;
  align-items: center;
  transform: translateX(10px);
}

.rtl .bottom-bar-right {
  transform: translateX(0);
}

.bottom-bar-right button {
  margin: 0 12px 0 0 !important;
  padding: 8px 16px !important;
}

.bottom-bar-right button:nth-child(1),
.bottom-bar-right button:nth-child(3) {
  border-radius: 0 0 0 10px;
}

.bottom-bar-right button:nth-child(2),
.bottom-bar-right button:nth-child(4) {
  border-radius: 0 10px 0 0;
  background-color: #05c3de;
}

.rtl .bottom-bar-right button:nth-child(1),
.rtl .bottom-bar-right button:nth-child(3) {
  border-radius: 0 10px 0 0;
}

.rtl .bottom-bar-right button:nth-child(2),
.rtl .bottom-bar-right button:nth-child(4) {
  border-radius: 0 0 0 10px;
}

.tb-social a.social-link {
  direction: ltr;
  display: inline-block;
}

.mb-logo {
  display: none;
  padding-bottom: 25px;
  border-bottom: 1px solid #ddd;
}

.mb-logo img {
  width: 115px;
}

.ah-header .container-fluid {
  padding-left: 0;
  padding-right: 0;
}

.top-bar .social-link {
  direction: ltr;
}

.top-bar .social-link span {
  color: #666;
  margin: 0 0 0 5px;
  display: inline-block;
}

.top-bar .tb-right .tb-search {
  padding: 4px 20px;
  position: relative;
  cursor: pointer;
}

.ah-menu a.ah-menu-link {
  padding: 0px 15px;
  font-size: 14px;
}

#hero .ah-btn {
  transition: 0.3s;
  width: fit-content;
  margin-bottom: 85px;
  padding: 15px 25px;
  background-color: #05c3de;
  border: 1px solid #05c3de;
}

#hero .ah-btn:hover {
  color: #05c3de !important;
  background-color: transparent;
}

.carousel-content .btn svg {
  margin: 0 0 0 7px;
}

.rtl .carousel-content .btn svg {
  margin: 0 7px 0 0;
  transform: rotate(180deg) translateY(-1px);
}

#hero .slider-title {
  margin: auto;
  color: #fff;
  max-width: 60%;
  font-size: 36px;
  font-weight: 900;
  text-align: center;
  line-height: 46px;
}

#hero .slider-description {
  color: #fff;
  max-width: 50%;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0;
  text-align: center;
  margin: 10px auto 30px;
}

#hero h2 span,
.blue-color {
  color: #05c3de;
}

div#heroCarousel .carousel-indicators button {
  height: 5px;
  width: 5px;
  transition: 0.3s;
  position: relative;
  border-radius: 100%;
  margin-right: 30px;
  background-color: transparent;
  border: 5px solid #ffffff99 !important;
}

body.rtl div#heroCarousel .carousel-indicators button {
  margin-left: 30px;
  margin-right: 0px;
}

div#heroCarousel .carousel-indicators button:last-child {
  margin: 0 !important;
}

div#heroCarousel .carousel-indicators button:before {
  content: "";
  width: 4px;
  height: 4px;
  border-radius: 100%;
  background-color: transparent;
  display: block;
  position: absolute;
  left: calc(50% - 2px);
  top: calc(50% - 2px);
}

div#heroCarousel .carousel-indicators button.active {
  border-color: #05c3de !important;
}

div#heroCarousel .carousel-indicators button.active:after {
  content: "";
  width: 35px;
  height: 35px;
  display: block;
  position: absolute;
  border: 2px solid #05c3de;
  border-radius: 100%;
  left: -15px;
  top: -15px;
}

#hero .carousel-item {
  height: 100%;
}

#hero .carousel-indicators,
body.rtl #hero .carousel-indicators {
  z-index: 3;
  bottom: 80px;
  margin-left: 135px;
  justify-content: flex-start;
}

body.rtl div#hero .carousel-indicators {
  margin-right: 135px;
}

section#services {
  position: relative;
  padding: 90px 60px;
}

section#services:before {
  content: "";
  background-color: #f4fcfd;
  width: calc(100% - 48px);
  height: calc(100% - 48px);
  display: block;
  position: absolute;
  z-index: -1;
  top: 24px;
  left: 24px;
}

.selectBranch {
  padding-right: 32px !important;
}

.rtl .selectBranch {
  padding-right: 10px !important;
}

.select__placeholder,
.form-control::placeholder {
  color: #808080ad !important;
}

.ah-section-title {
  font-weight: 800 !important;
  font-size: 40px;
  color: #333f48;
}

.service-card {
  display: flex;
  height: 320px;
  overflow: hidden;
  border-radius: 16px;
  background-size: cover !important;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.offer-meta {
  width: 100%;
  transition: 0.5s;
  margin-top: auto;
  padding: 15px 25px;
  height: fit-content;
  transform: translateY(66px);
  background: rgba(0, 0, 0, 0.6);
}

.offer-meta h3 {
  color: #fff;
  display: flex;
  font-size: 20px;
  min-height: 48px;
  margin: 0 0 22px;
  align-items: center;
}

.service-card:hover .offer-meta {
  transition: 0.5s;
  transform: translateY(0);
}

body.rtl .offer-meta .card-navs button {
  margin: 10px 0 0 15px;
}

.offer-meta a.ah-btn.btn.primary-btn i {
  margin-right: 6px;
}

.footer-social {
  margin-left: 50px;
  display: flex;
}

body.rtl .footer-social {
  margin-right: 50px;
  margin-left: 0px;
  display: flex;
}

.footer-social a.social-link {
  width: 50px;
  height: 50px;
  display: flex;
  flex-direction: row !important;
  border: 1px solid #e3e3e3;
  align-items: center;
  justify-content: center;
  margin-left: 15px;
}

.footer-social a:nth-child(1) {
  margin-left: 0;
}

body.rtl .footer-social a:nth-child(1) {
  margin-left: 15px;
}

.footer-social {
  position: relative;
}

.footer-social:before {
  content: "";
  width: 1px;
  height: 18px;
  position: absolute;
  left: -24.5px;
  background-color: #dcdcdc;
  top: calc(50% - 9px);
}

body.rtl .footer-social:before {
  content: "";
  width: 1px;
  height: 18px;
  position: absolute;
  right: -24.5px;
  left: auto;
  background-color: #dcdcdc;
  top: calc(50% - 9px);
}

.form-select:focus,
.form-control:focus {
  border-color: #ced4da !important;
  box-shadow: none;
}

.resp-bottom-bar-right {
  display: none;
}

/* .primary-btn:not(.white):hover {
  border: 1px solid #ffb71b;
  background-color: #ffffff00;
  color: #ffb71b !important;
} */

.primary-btn-roundLeft:hover {
  border: 1px solid #05c3de;
  background-color: #ffffff00;
  color: #ffb71b !important;
}

div#heroCarousel .carousel-indicators li:hover,
div#heroCarousel .carousel-indicators button:not(.active):hover {
  transition: 0.3s;
  border: 5px solid #ffffff !important;
}

.ab {
  margin-top: 33%;
  margin-left: 11%;
  margin-right: 43%;
}

.about_img {
  width: 100%;
  height: 100%;
}

.first {
  text-align: left;
  line-height: 35px;
}

body.rtl .first {
  font-family: "Cairo", sans-serif !important;
}

.first h2 {
  margin-top: 7%;
  color: #b5b5b5;
  font-weight: 900 !important;
  font-size: 40px;
}

body.rtl .first h2 {
  text-align: right;
}

.first h2 span {
  display: block;
  color: #333f48;
  font-weight: 900 !important;
  /*margin-right: 47%;*/
}

.first p {
  color: #6f767a;
  font-size: 20px;
  /*margin-right: 27%;*/
}

body.rtl .first p {
  text-align: right;
}

.mid {
  text-align: left;
  line-height: 35px;
  /*margin-bottom: 200px;*/
}

body.rtl .mid {
  font-family: "Cairo", sans-serif !important;
}

body.rtl .col-sm-6.mid-img {
  text-align: left;
}

body.rtl .about-us-sc .mid-img {
  text-align: right;
}

.mid h2 {
  margin-top: 7%;
  color: #b5b5b5;
  font-weight: 900 !important;
  font-size: 40px;
}

.mid h2 span {
  display: block;
  color: #333f48;
  font-weight: 900 !important;
  /* margin-right: 47%;*/
}

.mid p {
  color: #6f767a;
  font-size: 20px;
}

.gap {
  margin-bottom: 100px;
}

.second {
  font-size: 89px;
  color: #1fc0da;
}

body.rtl .second {
  font-family: "Cairo", sans-serif !important;
}

.second h2 span {
  display: block;
  color: #1fc0da;
  font-weight: 900 !important;
  font-size: 89px;
}

.second h2 {
  color: #1fc0da;
  font-weight: 900 !important;
  font-size: 89px;
}

.second p {
  color: #6f767a;
  font-size: 20px;
  margin-right: 27%;
}

body.rtl .second p {
  color: #6f767a;
  font-size: 20px;
  margin-left: 27%;
  margin-right: auto;
}

.ring img {
  float: right;
  width: 100%;
}

.upper {
  margin-top: 5% !important;
}

.location_content {
  width: 100%;
}

.location_heading {
  color: #333f48;
}

.location_heading h2 {
  margin: 0;
  font-size: 45px;
  font-weight: 900;
  text-align: center;
  text-transform: capitalize;
}

.offer-details-sec h2 {
  color: #333f48;
  margin-bottom: 0;
  font-size: 20px;
}

.recent_para {
  font-size: 18px !important;
  color: #333f48;
  font-weight: 900;
  line-height: 28px;
}

body.rtl .recent_para {
  font-family: "Cairo", sans-serif !important;
}

.recent_maincontent {
  width: 80%;
  color: #333f48;
  text-align: center;
  position: relative;
  color: #1c2b39;
  justify-content: center;
  margin: 0 auto 0 auto !important;
}

body.rtl .recent_maincontent {
  font-family: "Cairo", sans-serif !important;
}

.recent_maincontent h2 {
  font-size: 45px;
  font-weight: 900;
  margin-bottom: 0;
}

.recent-offer-main h4 {
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 25px;
}

.recent-offer-main.pb-5 {
  padding-bottom: 6rem !important;
}

.recent-offer-main .service-category-name {
  display: flex;
  font-size: 22px;
  font-weight: 500;
  margin: 15px 0 25px;
  align-items: center;
}

.recent-offer-main .service-category-name:first-child {
  margin: 5px 0 25px;
}

.recent-offer-main .service-category-name img {
  width: 35px;
  max-height: 35px;
  margin: 0 10px 0 0;
}

.recent-offer-main .category-name {
  margin-top: 50px;
  font-size: 25px;
  font-weight: 500;
}

.paid-service-row .category-name {
  margin-bottom: -20px;
}

.see-more-btn-wrapper {
  width: 100%;
  text-align: right;
  margin-top: 50px;
  margin-bottom: 20px;
}

.rtl .recent-offer-main .service-category-name img {
  margin: 0 0 0 10px;
}

.recent_maincontenttwo {
  margin: 20px auto 8px auto;
  justify-content: center;
  line-height: 26px;
  width: 30%;
  text-align: center;
  position: relative;
  font-size: 20px;
}

.ah-btn.primary-btn.white {
  background-color: #f7f5f2;
  color: rgb(0, 0, 0) !important;
}

.ah-btn.primary-btn.white.secondary {
  background-color: #dee2e6;
}

.appointments_content img {
  width: 100%;
  max-height: 280px;
  border-radius: 8px;
  margin-bottom: 35px;
}

.appointments_content h2 {
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 10px;
}

.appointments_content p {
  font-size: 16px;
  font-weight: 500;
  color: white;
  margin-bottom: 0;
}

body.rtl .appointments_content p {
  font-family: "Cairo", sans-serif !important;
}

.purple {
  background-color: #9264cc;
}

.orange {
  background-color: #ff8300;
}

.black {
  background-color: #1b2a36;
}

.blue {
  background-color: #1fc0da;
}

.our_partner {
  width: 100%;
  margin: 0 auto 50px;
}

.premium img {
  margin: 50px 0 50px 0;
  width: 100%;
}

.form-control {
  position: relative;
}

.box .inptFields {
  width: 100%;
  text-align: center;
}

body.rtl .accordion {
  font-family: "Cairo", sans-serif !important;
}

.checkbox-label-col {
  width: calc(100% - 70px);
  padding: 4px 0 0 0px;
}

.after h6::after {
  content: "";
  position: absolute;
  width: 150px;
  height: 4px;
  background-color: #1fc0da;
  bottom: -23px;
  left: calc(50% - 75px);
}

.title_form {
  font-size: 24px;
  font-weight: 800;
  color: #333f48;
  text-align: center;
  line-height: normal;
  text-transform: capitalize;
}

.title_form.congrats {
  font-size: 36px;
  margin-bottom: 0;
}

.title_form.congrats i {
  margin: 0 10px;
  color: #1fc0da;
}

.bluee {
  color: #1fc0da;
}

.center {
  display: flex;
  flex-direction: column;
}

.center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* h4 {
  color: #333f48;
} */

.input_two {
  width: 100%;
  display: flex;
  justify-content: center;
}

ul {
  list-style-type: none;
}

.error {
  color: #b71c1c;
}

.wizard .steps .first.done span,
.wizard .steps .current span,
.wizard .steps .done span {
  color: #fff;
}

.wizard .steps span {
  width: 100%;
  font-size: 18px;
  font-weight: 500;
}

.new_upload {
  margin: auto;
  max-width: 440px;
  margin-bottom: 40px;
  max-width: 440px !important;
}

.new_upload h1 {
  font-size: 20px;
  text-align: center;
  margin: 20px 0 20px;
}

.new_upload h1 small {
  display: block;
  font-size: 15px;
  padding-top: 8px;
  color: gray;
}

.enter-details-wrap {
  position: relative;
}

.personal-details form .react-tel-input input {
  height: 52px;
}

.wizard-details-wrap {
  position: relative;
}

.wizard-details-wrap::after {
  content: "";
  position: absolute;
  background-image: url(../images/phone-after.png);
  width: 425px;
  background-size: cover;
  bottom: 0;
  right: -12px;
  z-index: -1;
  top: -132px;
  height: 627px;
}

body.rtl .wizard-details-wrap::after {
  bottom: 0;
  left: -12px;
  right: auto;
  transform: rotate(180deg);
}

.basic-details-wrap {
  position: relative;
}

.wizard-location-wrap {
  position: relative;
}

.wizard-services-wrap {
  position: relative;
}

.wizard-services-wrap:before {
  content: "";
  position: absolute;
  height: 108px;
  background-image: url(../images/semi_blu.png);
  width: 54px;
  background-size: contain;
  bottom: 0px;
  left: -11px;
  top: 150px;
  z-index: 2;
}

.wizard-services-wrap:after {
  content: "";
  position: absolute;
  height: 109px;
  background-image: url(../images/semi_full.png);
  background-repeat: no-repeat;
  width: 163px;
  background-size: contain;
  bottom: 0px;
  right: -12px;
  top: calc(50% - 54px);
  z-index: 2;
}

.wizard-upload-img-wrap {
  position: relative;
}

.wizard-upload-img-wrap:before {
  content: "";
  position: absolute;
  height: 109px;
  background-image: url(../images/semi_filll.png);
  width: 163px;
  background-size: contain;
  bottom: 0px;
  left: -12px;
  top: 0;
  z-index: 2;
}

.time-slots-list {
  min-width: 105px;
  max-width: fit-content;
  border: 1px solid #ddd;
  border-radius: 0 8px 8px 0;
  border-left: 0;
}

body.rtl .time-slots-list {
  border-right: 0;
  border-left: 1px solid #ddd;
  border-radius: 8px 0 0 8px;
}

.time-slots-list .slots {
  width: 100%;
  overflow: auto;
  position: relative;
}

.time-slots-list .slots::-webkit-scrollbar {
  width: 2px;
}

.time-slots-list .slots::-webkit-scrollbar-thumb {
  background-color: rgba(38, 126, 151, 0.05);
}

.time-slots-list .slots::-webkit-scrollbar-track {
  background-color: #f2f2f2;
}

.time-slots-list .slots input {
  display: none;
}

.time-slots-list .slots label {
  width: 100%;
  cursor: pointer;
  font-size: 14px;
  user-select: none;
  padding: 10px 20px;
  text-align: center;
}

.time-slots-list .select-size .header {
  display: flex;
  height: 3.21em;
  padding: 0 8px;
  align-items: center;
  justify-content: center;
  background-color: rgba(38, 126, 151, 0.05);
}

.time-slots-list .select-size .header p {
  color: #000;
  font-size: 14px;
  margin-bottom: 0;
  text-align: center !important;
}

.time-slots-list label.active.no-click {
  pointer-events: none;
}

.time-slots-list label.disabled,
.time-slots-list label.active.no-click {
  opacity: 0.7;
  display: flex;
  font-size: 13px;
  padding: 10px 5px;
  align-items: center;
  background-color: #ccc;
}

input:checked~label.active {
  color: #fff;
  background-color: #05c3de;
}

.row.check {
  width: 100%;
}

.enter-details-wrap::before {
  content: "";
  position: absolute;
  background-image: url(../images/phone-details-after.png);
  width: 318px;
  background-size: cover;
  bottom: -60px;
  left: 55px;
  z-index: -1;
  height: 74px;
}

body.rtl .enter-details-wrap::before {
  right: 55px;
  left: auto;
}

.wrap {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.rd-back:before {
  padding: 19px 0 44 0 !important;
}

.rd-month-label {
  padding: 19px 0 44px 0 !important;
}

/* Appointment DayPicker CSS */
.slots-picker .rdp {
  margin: 0;
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 8px 0 0 8px;
}

body.rtl .slots-picker .rdp {
  border-radius: 0 8px 8px 0;
}

.slots-picker .rdp-month,
.slots-picker .rdp-table {
  min-width: 100%;
}

.slots-picker .rdp-table {
  margin: 5px 0;
}

.slots-picker .rdp-caption {
  width: 100%;
  border-radius: 0;
  padding: 12px 15px;
  background: rgba(38, 126, 151, 0.05);
}

.slots-picker .rdp-caption_label {
  border: 0;
  padding: 0;
  z-index: unset;
}

.slots-picker .rdp-nav_button {
  border: 0;
  width: auto;
  height: auto;
}

.slots-picker .rdp-cell {
  padding: 7px 0;
}

.slots-picker .rdp-cell button {
  border: 0;
  width: 30px;
  height: 30px;
  margin: auto;
  color: #fff;
  font-size: 14px;
  border-radius: 4px;
  opacity: 1 !important;
}

.slots-picker .rdp-cell button .day {
  width: 100%;
  height: 100%;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  background-color: #f2f2f2;
}

.slots-picker .rdp-cell button .day.true {
  background-color: #4caf50;
}

.slots-picker .rdp-cell button .day.false {
  background-color: #f44336;
}

.slots-picker .rdp-cell button .day.false.holiday {
  background-color: transparent;
  color: #ccc !important;
}

.slots-picker .rdp-cell button:has(> .day.false) {
  pointer-events: none;
}

.slots-picker .rdp-day_selected:not(.rdp-day_disabled):not(.rdp-day--outside) .day {
  background-color: #05c3de;
}

.slots-picker .rdp-day_disabled .day {
  color: #ccc;
  background-color: transparent !important;
}

.slots-picker .rdp-day_disabled {
  pointer-events: none;
}

.slots-picker .rdp-day_selected:not(.rdp-day_disabled):not(.rdp-day--outside),
.slots-picker .rdp-nav button {
  background-color: transparent !important;
}

/* Appointment DayPicker CSS */

.row.no-wrap {
  flex-wrap: unset;
}

.row.no-wrap .col-sm-6 {
  margin-top: 0;
}

.premium-partner-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 60px;
}

.premium-partner-row .premium-partner-col {
  width: 46%;
}

.track-dots {
  display: flex;
  align-items: center;
}

.track-dots .dot {
  margin-right: 0;
  border-radius: 100%;
  margin-bottom: 0;
  border: 10px solid #fff;
  height: 45px;
  width: 46.15px;
}

.track-dots .line {
  margin-top: 0;
  border-radius: 0;
}

.wizard .steps ul li {
  display: flex;
  align-items: center;
  padding: 8px 30px 8px 25px;
}

body.rtl .wizard .steps ul li {
  padding: 6px 30px 10px 25px;
}

body.rtl .wizard ul span {
  font-size: 16px;
}

.wizard .steps ul li {
  position: relative;
  overflow: hidden;
  border-radius: 0;
}

.wizard-main-wrapper .content.clearfix {
  padding: 0;
  background-color: transparent;
}

.wizard .actions li a,
.wizard .actions li input[type="submit"],
.wizard .actions li button[type="submit"] {
  background-color: #ffb71b;
  color: #fff !important;
  border-radius: 0 0 25px;
  text-transform: uppercase;
  padding: 10px 30px !important;
  font-weight: 600;
  text-align: center;
  margin-left: 0;
  border: 1px solid #ffb71b;
  transition: 0.3s;
}

.wizard .actions li:not(:last-child) a,
.wizard .actions li:not(:last-child) input[type="submit"] {
  margin-right: 20px;
}

body.rtl .wizard .actions li:first-child a,
body.rtl .wizard .actions li:first-child input[type="submit"] {
  margin: 0 0 0 20px;
}

.wizard .actions li a:hover,
.wizard .actions li input[type="submit"]:hover {
  transition: 0.3s;
  color: #ffb71b;
  background-color: transparent;
  border: 1px solid #ffb71b;
}

.wizard .actions>ul {
  max-width: unset !important;
  margin-left: 0 !important;
  justify-content: center !important;
}

#wizard-p-2 .actions ul {
  justify-content: center;
}

.phone-no-wrap {
  display: flex;
  direction: ltr;
  margin: 0 10px 0 0;
}

.rtl .phone-no-wrap {
  margin: 0 0 0 10px;
}

.schedule-email {
  margin: 0 5px 0 0;
}

.react-tel-input .selected-flag .flag {
  left: 12px !important;
}

.react-tel-input .selected-flag {
  width: 44px !important;
}

.rtl .schedule-email {
  margin: 0 0 0 -5px;
}

.phone-number-codes input {
  width: 100% !important;
  padding-left: 60px !important;
}

.rtl .phone-number-codes input {
  padding-left: 0 !important;
  padding-right: 60px !important;
  direction: ltr !important;
  text-align: right !important;
}

.rtl .react-tel-input .selected-flag .arrow {
  right: 20px;
  left: 20px;
}

.rtl .react-tel-input .selected-flag {
  width: 42px;
  padding: 0 8px 0 0;
}

.rtl .react-tel-input .country-list .flag {
  margin-left: 7px;
  margin-right: 10px;
  margin-top: 2px;
}

.estimation-purpose-wrapper {
  padding: 10px 10px;
  line-height: 30px;
  border: 1px solid #ced4da;
  margin: 10px 0;
  box-shadow: 1px 1px 1px rgb(0 0 0 / 5%);
}

#phoneCode {
  max-width: 65px !important;
  border-right: 0 !important;
  border-radius: 8px 0 0 8px !important;
}

#phoneNumber {
  border-radius: 0 8px 8px 0 !important;
}

.wizard-sec-width {
  width: 70%;
  margin: 40px auto 25px;
}

.wizard .actions ul {
  width: 70%;
  margin: 0px auto !important;
}

body.rtl .wizard-main-wrapper .steps ul {
  padding: 0px;
}

.wizard .steps ul li.current a {
  background-color: transparent !important;
}

.wizard-main-wrapper .steps ul li.first.current {
  background-image: url(../images/step-first-active.png);
}

.wizard-main-wrapper .steps ul li.first.done {
  background-image: url(../images/step-first-active.png);
  opacity: 0.5;
}

body.rtl .wizard-main-wrapper .steps ul li.first.current {
  background-image: url(../images/step-first-active-ar.png);
}

body.rtl .wizard-main-wrapper .steps ul li.first.done {
  background-image: url(../images/step-first-active-ar.png);
  opacity: 0.5;
}

.wizard-main-wrapper .steps ul li:nth-child(2) {
  background-image: url(../images/step-inactive.png);
}

.wizard-main-wrapper .steps ul li.current:nth-child(2) {
  background-image: url(../images/step-active.png);
}

.wizard-main-wrapper .steps ul li.done:nth-child(2) {
  background-image: url(../images/step-active.png);
  opacity: 0.5;
}

body.rtl .wizard-main-wrapper .steps ul li:nth-child(2) {
  background-image: url(../images/step-inactive-ar.png);
}

body.rtl .wizard-main-wrapper .steps ul li.current:nth-child(2) {
  background-image: url(../images/step-active-ar.png);
}

body.rtl .wizard-main-wrapper .steps ul li.done:nth-child(2) {
  background-image: url(../images/step-active-ar.png);
  opacity: 0.5;
}

.wizard-main-wrapper .steps ul li.last {
  background-image: url(../images/step-last-inactive.png);
}

.wizard-main-wrapper .steps ul li.last.current {
  background-image: url(../images/step-last-active.png);
}

body.rtl .wizard-main-wrapper .steps ul li.last {
  background-image: url(../images/step-last-inactive-ar.png);
}

body.rtl .wizard-main-wrapper .steps ul li.last.current {
  background-image: url(../images/step-last-active-ar.png);
}

.wizard .steps ul li {
  background-size: 100% 100% !important;
  background-repeat: no-repeat;
}

body.rtl .wizard .steps ul li {
  background-repeat: no-repeat;
}

.wizard-main-wrapper .steps ul li {
  min-width: fit-content;
  max-width: fit-content;
}

.rd-month-label {
  background-color: #f8f8f8 !important;
  border: 1px solid #dcdcdc !important;
  border-radius: 8px;
}

.rd-back:before {
  content: "\f053" !important;
  font-family: "Font Awesome 5 Pro" !important;
}

table.rd-days {
  width: 100% !important;
}

.rd-month {
  width: 100% !important;
}

.rd-date {
  width: 100% !important;
}

td.rd-day-body span {
  padding: 16px;
  border-radius: 100%;
}

/* Wizard */
.wizard a:hover {
  text-decoration: none;
}

.wizard .audible {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

@media (max-width: 767px) {
  .wizard .steps ul li {
    padding: 15px 10px;
  }
}

@media (max-width: 767px) {
  .wizard .steps ul li .media {
    width: 100%;
    display: block;
  }
}

.wizard .steps ul li.current a {
  background-color: #05c3de;
}

.wizard .content {
  padding: 100px 35px 20px 0px;
}

@media (max-width: 767px) {
  .wizard .content {
    padding-left: 20px;
    padding-right: 20px;
    min-height: auto;
  }
}

.wizard .content .title {
  display: none;
}

.wizard .content p:not(.red-color):not(.no-general) {
  color: #666;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
}

.wizard .actions>ul {
  list-style: none;
  padding-left: 0;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: end;
  justify-content: flex-end;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  /* padding-bottom: 15px; */
}

body.rtl .wizard .actions>ul {
  padding-left: auto;
  padding-right: 0px;
}

.wizard .actions li a {
  display: inline-block;
  border-radius: 0;
  border-bottom-left-radius: 25px !important;
  background-color: #ffb71b;
  padding: 16px 30px;
  color: #fff;
  font-weight: bold;
}

.wizard .actions li.disabled {
  display: none;
}

.wizard .steps>ul {
  width: 70%;
  margin: auto;
  display: flex;
  padding-left: 0;
  list-style: none;
  justify-content: center;
}

.schedule-appointment-main .wizard .content .form-control,
.track-your-progress-main .wizard .content .form-control {
  padding: 15px;
  font-size: 14px;
  margin: 10px 0 0;
  border-radius: 8px;
  border-color: #ddd;
}

.schedule-appointment-main .wizard .content textarea.form-control {
  height: 150px;
  resize: none;
}

.form-control:disabled,
.form-control[readonly] {
  color: #999999;
  background-color: #f2f2f2 !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.loader {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.7);
}

.loader img {
  max-width: 300px;
}

.estimation .wizard .actions ul {
  list-style: none;
  padding-left: 0;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: end;
  justify-content: center !important;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 15px;
  border-bottom: 1px solid #f5f5f4;
  text-align: center;
}

.estimation .actions>ul {
  justify-content: center !important;
}

.estimation .steps>ul {
  width: 100% !important;
  justify-content: center !important;
}

body.rtl .estimation .steps>ul {
  width: 100% !important;
  justify-content: center !important;
}

.wizard-main-wrapper .steps ul li.current:nth-child(3) {
  background-image: url(../images/step-last-active.png);
}

.wizard-main-wrapper .steps ul li.done:nth-child(3) {
  background-image: url(../images/step-active.png);
  opacity: 0.5;
}

.wizard-main-wrapper .steps ul li.done:nth-child(3) .bd-wizard-step-title,
.wizard-main-wrapper .steps ul li.done:nth-child(3) .bd-wizard-step-title span {
  color: #fff !important;
}

.wizard-main-wrapper .steps ul li.current:nth-child(4) {
  background-image: url(../images/step-last-active.png);
}

body.rtl .wizard-main-wrapper .steps ul li.current:nth-child(4) {
  background-image: url(../images/step-last-active-ar.png);
}

body.rtl .wizard-main-wrapper .steps ul li.done:nth-child(3) {
  background-image: url(../images/step-active-ar.png);
  opacity: 0.5;
}

body.rtl .fof .ah-btn.btn.primary-btn.primary-btn-big {
  margin: 0 auto;
}

.track-form-box form {
  width: 50%;
  margin: auto;
  padding: 0 15px;
}

.track-form-box .phone-no-wrap input {
  padding: 12px;
}

.d-none {
  display: none !important;
}

.d-block {
  display: block !important;
}

.estimation .p-width p {
  width: 100%;
}

ul.multiimages-uploads {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin-top: 50px;
}

.multiimages-uploads li {
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
  margin: 5px 5px 48px 5px;
  background-color: #f5f5f5;
  border: 1px dashed #dcdcdc;
  overflow: hidden;
  position: relative;
}

.multiimages-uploads li img {
  width: 100%;
}

.wizard-main-wrapper:not(.estimation) .actions li a:hover,
.wizard-main-wrapper:not(.estimation) .actions li input[type="submit"]:hover,
.actions li button[type="submit"]:hover {
  background-color: #fff !important;
  border-color: #ffb71b !important;
  color: #ffb71b !important;
}

.wizard-main-wrapper:not(.estimation) .actions li a,
.wizard-main-wrapper:not(.estimation) .actions li input[type="submit"] {
  background-color: #ffb71b;
  border: 1px solid #ffb71b;
}

.rtl .wizard-main-wrapper:not(.estimation) .actions li a {
  border-radius: 0 0 25px 0 !important;
}

.rtl .wizard-main-wrapper:not(.estimation) .actions li input[type="submit"] {
  border-radius: 0 0 0 25px !important;
}

.rtl .wizard-main-wrapper:not(.estimation) .actions li a {
  border-radius: 0 0 25px 0 !important;
}

.rtl .wizard-main-wrapper:not(.estimation) .actions li input[type="submit"],
.rtl .wizard-main-wrapper:not(.estimation) .actions li button[type="submit"] {
  border-radius: 0 0 0 25px !important;
}

.remove-img {
  position: absolute;
  top: 0px;
  right: 0;
  font-size: 10px;
  background: #fff;
  padding: 5px 5px;
  border-radius: 100px;
  color: #fff !important;
  height: 30px;
  width: 30px;
  cursor: pointer;
}

.row.upper img {
  margin-bottom: 20px;
}

.form-control:focus {
  box-shadow: none !important;
}

button:focus,
a:focus,
input[type="button"],
select:focus {
  box-shadow: none !important;
}

.upper:before {
  margin-right: 10px;
}

.limit {
  margin: 15px 0 30px 0;
}

.about_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.red-color {
  font-size: 13px;
  line-height: 16px;
  display: inline-block;
  color: red !important;
}

.error-time {
  margin: auto;
  max-width: 94px;
  padding: 10px 5px;
}

.new_upload.image-uploaded label {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0 !important;
  top: 0 !important;
  display: block;
}

body.rtl .form-select {
  background-position: left 0.75rem center;
}

.OTP {
  text-align: center;
  justify-content: center;
}

.OTP .form-control {
  text-align: center;
  max-width: fit-content !important;
  min-width: fit-content !important;
}

.OTP .form-control::selection {
  background-color: #ddd;
}

body.rtl .row.OTP {
  direction: ltr;
}

body.rtl #verifyUser p.number {
  text-align: center;
  direction: ltr;
}

body.rtl .bluee.number.rightalign {
  text-align: right !important;
}

/* 404 page start*/
#main {
  display: table;
  width: 100%;
  height: 100vh;
  text-align: center;
}

.fof {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url(../images/404-banner-bg.png);
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  position: relative;
  z-index: 1;
}

.fof:before {
  content: "";
  background-color: rgb(0 0 0 / 40%);
  width: 100%;
  height: 100vh;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
}

.fof h1 {
  color: #fff;
  font-size: 72px;
  font-weight: 900;
  display: inline-block;
}

.fof h2 {
  color: #fff;
  font-size: 30px;
  margin-bottom: 50px;
  display: inline-block;
}

/* 404 page end*/

/* contact us page */
.contact-page-detail {
  width: 100%;
  aspect-ratio: 16/6;
  background-size: cover;
  background-position: center;
}

.contact-page-detail>div {
  color: #fff;
  height: 100%;
  text-align: center;
  padding: 100px 0 0;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.1) 10%, #222629 100%);
}

.contact-page-detail h2 {
  font-size: 50px;
  font-weight: 900;
}

.contact-page-detail p {
  margin: 0;
  margin: auto;
  max-width: 50%;
  font-size: 20px;
  line-height: 26px;
}

.contact-clean {
  padding: 40px 0;
  position: relative;
  margin: -100px 0 50px;
}

.contact-clean form {
  width: 90%;
  padding: 30px;
  margin: 0 auto;
  color: #505e6c;
  max-width: 600px;
  border-radius: 20px;
  background-color: #ffffff;
  box-shadow: 0px 4px 25px rgb(0 0 0 / 6%);
}

.contact-clean form::after {
  content: "";
  top: 120px;
  bottom: 0;
  right: 0;
  z-index: -1;
  width: 300px;
  position: absolute;
  background-size: cover;
  background-image: url(../images/phone-after.png);
}

.contact-clean form::before {
  content: "";
  left: 55px;
  z-index: -1;
  width: 318px;
  height: 74px;
  bottom: -50px;
  position: absolute;
  background-size: cover;
  background-image: url(../images/phone-details-after.png);
}

.contact-clean h2 {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 20px;
}

.contact-clean .form-group {
  margin-bottom: 10px;
}

.contact-clean form .form-control {
  padding: 8px 12px;
  border-radius: 6px;
  background-color: #fff;
  border-color: #ddd;
}

.contact-clean form .react-tel-input input {
  height: 42px;
}

.contact-clean form textarea.form-control {
  resize: none;
  height: 100px;
  padding-top: 10px;
  border-color: #ddd;
}

.contact-clean .contacts-check label {
  display: flex;
  line-height: 20px;
  align-items: center;
  max-width: fit-content;
}

.contact-clean form .btn {
  padding: 16px 32px;
  border: none;
  background: none;
  box-shadow: none;
  text-shadow: none;
  opacity: 0.9;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 13px;
  letter-spacing: 0.4px;
  line-height: 1;
  outline: none !important;
}

.contact-clean form .btn:hover {
  opacity: 1;
}

.contact-clean form .btn:active {
  transform: translateY(1px);
}

.contact-clean form .btn-primary {
  background-color: #ffb71b;
  margin-top: 15px;
  color: #fff;
}

.socials-contact a:not(:last-child) {
  margin-right: 20px;
}

body.rtl .socials-contact a:not(:last-child) {
  margin-right: 0;
  margin-left: 20px;
}

.blue-color {
  color: #05c3de;
  margin-top: 15px;
}

.contact-disabled-button {
  cursor: not-allowed !important;
  background-color: rgb(229, 229, 229) !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.logout-btn {
  line-height: 0;
}

.signin-login .login-btn,
.my-account-btn {
  color: #05c3de;
  cursor: pointer;
}

body.rtl #topbar i {
  margin: 0 5px;
}

.lang-box button {
  margin: 0;
  color: #fff;
  height: 26px;
  padding: 0 5px;
  font-size: 14px;
  font-weight: 600;
  padding-bottom: 0;
  width: fit-content;
  border-style: none;
  background-color: #a79e8b;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.resp-bottom-bar-right .lang-box {
  margin-left: 15px;
}

body.rtl .resp-bottom-bar-right .lang-box {
  margin-right: 15px;
}

.top-bar .whatsupIcon {
  margin-left: 7px;
  font-weight: 900;
}

.contactInfo {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  min-height: 150px;
  background-color: #f8f8f8;
  padding: 30px 0;
}

.contactInfo p {
  margin-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 24px;
}

.contactInfo p a {
  color: #6f767a;
}

.contactInfo .tb-social {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 25px;
}

.contactInfo .tb-social strong {
  margin-bottom: 10px;
}

.contactInfo .tb-social ul {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin-bottom: 0;
}

.contactInfo .tb-social ul .social-link i {
  font-size: 24px;
}

/* login form */
.login-form-main {
  padding: 10px 30px;
  /* text-align: center; */
}

.loginphone-number-codes input {
  width: 100% !important;
  padding-left: 60px !important;
}

.login-form-main form .react-tel-input input {
  height: 42px;
  padding-left: 58px !important;
}

.login-form-main a.left-round {
  border-radius: 0 0 0 25px;
}

.rtl .login-form-main a.left-round {
  border-radius: 0 0 25px 0;
}

.errorMassage {
  text-align: left;
}

.login-form-main .form-control,
.track-form-box .form-control,
.login-form-main #phoneCode {
  padding: 8px;
}

.login-form-main h4 {
  font-weight: 600;
  margin-bottom: 12px;
}

.login-form-main h6 {
  font-size: 16px;
  margin-bottom: 5px;
}

.login-form-main p {
  font-size: 14px;
  line-height: 18px;
}

.modal {
  padding: 0 !important;
}

.checkBox label {
  padding: 0 5px;
}

.uplofFileCustom {
  display: flex;
  align-items: center;
  width: 100%;
  border: 1px solid #d1d7dc;
  padding: 5px 0;
  border-radius: 0.25rem;
}

.custom-file-input {
  width: 110px;
  line-height: 0;
  color: transparent;
}

.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}

.uploadCv::before {
  width: 100% !important;
}

.custom-file-input::before {
  width: 100px;
  height: 30px;
  display: flex;
  outline: none;
  color: #212529;
  display: flex;
  outline: none;
  cursor: pointer;
  font-size: 14px;
  border-radius: 4px;
  white-space: nowrap;
  align-items: center;
  justify-content: center;
  border: 1px solid #ced4da;
  background-color: #f2f2f2;
}

.file-upload {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.file-upload span {
  margin: -3px 8px 0;
  display: inline-block;
}

.file-upload span svg {
  margin: 0 8px;
  cursor: pointer;
  transition: 0.3s;
}

.file-upload span svg:hover {
  color: red;
  transition: 0.3s;
}

.keys-provided-checkbox {
  margin-bottom: 20px;
}

.keys-provided-checkbox label {
  font-size: 18px;
}

.rtl .uploadCv {
  width: 140px !important;
}

.custom-file-input:hover::before {
  border-color: black;
}

.custom-file-input:active {
  outline: 0;
}

.custom-file-input:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}

.phone-no-wrap-contact-us {
  display: flex;
}

.modal-close-btn {
  margin-left: 430px;
  background-color: #ffb03b;
  border-block: #ffb03b;
}

body.rtl .phone-code {
  direction: ltr;
}

.contact-clean .phone-no-wrap input.phone-code {
  max-width: 60px !important;
  text-align: center;
  padding: 10px !important;
}

body.rtl {
  direction: rtl;
  unicode-bidi: isolate;
  -webkit-locale: "ar";
}

.d-none {
  display: none;
}

.appointments_content .discount_details {
  display: flex;
  flex-wrap: wrap;
}

.appointments_content .discount_details p {
  margin-right: 20px;
  min-width: fit-content;
}

.appointments_head {
  margin-bottom: 30px;
}

.appointments_head h2 {
  font-size: 50px;
  font-weight: 900;
}

.appointments_content {
  height: 100%;
  display: flex;
  padding: 20px;
  border-radius: 12px;
  flex-direction: column;
  background-color: #3d5a80;
  justify-content: space-between;
}

.grey {
  background-color: #c5c5c5;
}

.appointments_content.grey img {
  opacity: 0.7;
}

.appointments_content p {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.2px;
}

.appointments_content p span {
  font-weight: 600;
}

.appointments_content .card-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.appointments_content .notification-icon {
  cursor: pointer;
  color: #ffb71b;
}

.appointments_content .icon-container {
  position: relative;
  display: inline-block;
}

.appointments_content .icon-container .red-dot {
  position: absolute;
  top: -3px;
  right: -3px;
  width: 8px;
  height: 8px;
  background-color: #00c0de;
  border-radius: 50%;
}

.re-appointment-btn {
  margin-top: 15px;
}

.re-appointment-btn button {
  margin-top: 10px;
  font-size: 12px !important;
  padding: 6px 15px !important;
}

.re-appointment-btn button:first-child {
  margin-right: 10px;
  border-radius: 0 0 0 15px;
}

.rtl .re-appointment-btn button:first-child {
  margin-right: 0;
  margin-left: 10px;
  border-radius: 0 0 15px 0;
}

.re-appointment-btn button:last-child {
  border-radius: 0 0 15px 0;
}

.rtl .re-appointment-btn button:last-child {
  border-radius: 0 0 0 15px;
}

.re-appointment-btn span {
  margin: 0;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  padding: 4px 10px;
  border-radius: 4px;
  display: inline-block;
  max-width: fit-content;
  background-color: #dc3545;
}

.re-appointment-btn span.completed {
  background-color: #23c275;
}

.re-appointment-btn span svg {
  margin: 0 4px 0 0;
  transform: translateY(0.8px);
}

.rtl .re-appointment-btn span svg {
  margin: 0 0 0 4px;
}

/* Message box */
.message-box {
  margin-top: 40px;
  padding: 5px 15px;
  max-width: fit-content;
  border: 1px solid transparent;
}

.message-box.error {
  color: red;
  border-color: red;
  background-color: rgba(255, 0, 0, 10%);
}

.message-box.success {
  color: green;
  border-color: green;
  background-color: rgba(0, 128, 0, 15%);
}

.message-box span {
  font-size: 18px;
}

/* Cancel Appointment Modal */
.cancel-appointment-modal {
  padding: 10px 15px 15px;
}

.cancel-appointment-modal p {
  line-height: 22px;
}

.cancel-appointment-modal #reason {
  height: 120px;
  margin-top: 20px;
}

.cancel-appointment-modal .controls {
  display: flex;
  margin-top: 30px;
  justify-content: flex-end;
}

.cancel-appointment-modal .cancelBtn {
  border: 1px solid #ffb71b;
}

.cancel-appointment-modal .closeBtn {
  color: #000 !important;
  background-color: #ddd;
  border: 1px solid #ddd;
}

/* Search Appointment */
.no-results {
  text-align: center;
  margin: 0 0 20px !important;
}

/* Service detail */
.service-detail-content h2 {
  color: #333f48;
  font-size: 40px;
  font-weight: 800;
  margin-bottom: 20px;
}

.service-detail-content p.shortDesc {
  line-height: 28px;
  margin-bottom: 20px;
  font-size: 20px;
}

.shortDesc ul {
  font-family: "MarkPro", Arial;
  list-style-type: inherit !important;
}

.shortDesc ul li {
  margin-bottom: 4px;
}

.rtl .shortDesc ul {
  font-family: "Cairo";
}

.shortDesc {
  font-weight: 400;
}

.service-detail-content-text p span {
  line-height: 20px;
  font-size: 16px !important;
}

.service-detail-content-text li {
  list-style-type: disc !important;
}

.service-image img {
  width: 100%;
  border-radius: 10px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

/* Signin/up screen (Schedule Appointment) */
#stepOneForm .pr-md-0 {
  padding-right: 0;
}

.at-sc-username {
  display: flex;
  justify-content: center;
}

.at-sc-username>div {
  margin-right: 5px;
}

#stepOneForm .username .input_two {
  padding-bottom: 0;
}

#mrMs,
#phoneCode {
  min-width: 80px;
}

#Year {
  color: #888;
}

#phoneCode {
  text-align: center;
}

.carPlateNumber {
  display: flex;
  margin: 7px 0;
  justify-content: center;
}

.carPlateNumber>div.pr-0 {
  padding-right: 0;
}

.carPlateNumber>div.pr-0 input {
  margin: 5px 0;
  border-right: 0 !important;
  border-radius: 8px 0 0 8px !important;
}

.carPlateNumber>div.pl-0 {
  padding-left: 0;
}

.carPlateNumber>div.pl-0 input {
  border-radius: 0 8px 8px 0 !important;
}

body.rtl .carPlateNumber>div.pr-0 input {
  border-left: 0 !important;
  border-radius: 0 8px 8px 0 !important;
  border-right: 1px solid #c4c0c0 !important;
}

body.rtl .carPlateNumber>div.pl-0 input {
  border-radius: 8px 0 0 8px !important;
}

.car-img-upload-title {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.car-img-upload-title label {
  width: 30px;
  height: 30px;
  display: flex;
  padding: 8px;
  cursor: pointer;
  border-radius: 100%;
  align-items: center;
  margin: 0 0 20px 10px;
  justify-content: center;
  background-color: #1fc0da;
}

.rtl .car-img-upload-title label {
  margin: 0 10px 20px 0;
}

.car-img-upload-title svg {
  color: #fff;
  font-size: 18px;
}

body.rtl .accordion-button::after {
  margin-left: 0;
  margin-right: auto;
}

.required-flag {
  color: #ff4a4a;
  margin-left: 5px;
}

body.rtl .required-flag {
  margin: 0 5px 0 0;
}

/* Estimation request */
.estimation-request {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.estimation-request>div {
  padding: 20px;
  max-width: 23%;
  min-width: 23%;
  margin-right: 15px;
  margin-bottom: 15px;
  border-radius: 15px;
  background-color: #1fc0da;
}

body.rtl .estimation-request>div {
  margin-right: 0;
}

.estimation-request>div h4 {
  font-size: 22px;
  font-weight: 800;
  color: #fff !important;
}

.estimation-request>div p {
  margin: 0;
  color: #fff;
  font-weight: 500;
}

.estimation-request>div p span {
  font-weight: 700;
}

.recent-offer-main {
  border-radius: 24px;
  padding: 40px 55px 25px;
  background-color: #fafafa;
}

.recent-offer-main .row>div,
.recent_maincontent.pp h2 {
  margin-bottom: 30px !important;
}

.session-expired {
  line-height: 20px;
  margin: 20px 0 0;
  text-align: center;
  color: red !important;
}

.selected-date {
  color: #05c3de;
  font-size: 14px;
  margin: 18px 0 0;
  line-height: 20px;
}

.selected-date span {
  color: #000;
}

.car-select {
  padding-right: 0 !important;
}

.car-select .select__control,
.step3__control {
  margin: 5px 0 0;
  font-size: 14px;
  padding: 7px 5px;
  box-shadow: none !important;
  border-radius: 8px !important;
  border: 1px solid #ddd !important;
}

.car-select .select__control span,
.step3__control span {
  width: 0;
}

.step3__menu-list::-webkit-scrollbar,
.select__menu-list::-webkit-scrollbar {
  width: 8px;
}

.step3__menu-list::-webkit-scrollbar-thumb,
.select__menu-list::-webkit-scrollbar-thumb {
  background-color: #e2e2e2;
}

.step3__menu-list * {
  line-height: 18px;
  font-size: 14px !important;
}

.step3__group-heading {
  font-size: 12px !important;
}

.step3__option {
  display: flex !important;
  align-items: flex-start;
}

.car-select .select__menu {
  z-index: 4;
}

.car-meta,
.service-select,
.locate-branch {
  display: flex;
  margin: 10px 0;
  justify-content: center;
}

.car-meta>div {
  margin-right: 5px;
}

.schedule-appointment-main {
  position: relative;
}

.schedule-appointment-main .form-control:not(.no-width),
.locate-branch .step3__control {
  min-width: 230px;
  max-width: 230px;
}

.schedule-appointment-main .wizard .content .expand-inp-width {
  margin: auto;
  min-width: 475px;
  max-width: 475px;
}

#ErrorCollectionAddress,
#ErrorDeliveryAddress {
  margin: auto;
  max-width: 475px;
}

.locate-branch>div {
  margin: 0 7px;
}

body.rtl .recent-offer-main .appointments_content {
  direction: rtl;
}

.offers-pagination {
  margin-top: 80px;
}

.bring-it-appointments .titles {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 30px;
  align-items: center;
}

.bring-it-appointments .titles h2 {
  margin: auto;
  font-size: 45px;
  padding-left: 0;
  font-weight: 900;
  text-align: center;
}

.bring-it-appointments>div:not(.titles):not(.loader) {
  margin: 12px 0;
}

.address-details {
  margin: auto;
  max-width: 490px;
  padding-left: 7px;
  margin-top: 12px !important;
}

.address-details p {
  margin: 0;
  color: #333f48 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  font-weight: 500 !important;
}

.address-details p span {
  color: #666;
}

.address-details a {
  font-size: 14px;
  font-weight: 500;
  min-width: fit-content;
  color: #05c3de !important;
}

.address-details svg {
  margin-left: 6px;
}

.rtl .address-details svg {
  margin-right: 6px;
}

.address-details a:hover {
  color: #ffb71b !important;
}

.login-modal-close-btn {
  top: -8px;
  right: -10px;
  color: #fff;
  border: none;
  display: flex;
  font-size: 14px;
  padding: 8px 10px;
  position: absolute;
  align-items: center;
  border-radius: 100%;
  background-color: #ffb03b;
}

.offer-details-sec .accordion {
  padding: 0 !important;
}

.offer-details-sec .accordion-button:not(.collapsed) {
  box-shadow: none;
}

.offer-details-sec {
  width: 80%;
  margin: 0 auto;
}

.offer-details-sec h1 {
  margin: 0 0 30px;
  color: #05c3de;
  font-weight: 700;
  text-align: center;
}

.offer-details-sec p {
  margin: 0;
  font-size: 16px;
  font-family: "MarkPro", Arial;
}

.recent-details-img img {
  width: 80%;
  border: 15px solid #57dffa;
}

.recent-details-img {
  text-align: center;
}

.button-and-expiry-wrap {
  width: 80%;
  display: flex;
  margin: 0 auto;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

.book-btn-wrapper button {
  font-size: 16px !important;
  padding: 10px 30px !important;
}

.book-btn-wrapper>button:hover {
  border-color: #fff;
  color: #fff !important;
  background-color: #303e49;
}

.campaign-metadata {
  display: flex;
  align-items: flex-start;
}

.campaign-metadata>div {
  line-height: 20px;
  border-style: solid;
  border-color: #ddd;
  border-width: 0 1px 0 0;
}

.campaign-metadata>div:first-child {
  padding: 0 10px 0 0;
}

.campaign-metadata>div:last-child {
  border-width: 0;
  padding: 0 0 0 10px;
}

.campaign-metadata .middle {
  padding: 0 10px;
}

.rtl .campaign-metadata>div:last-child {
  padding: 0 10px 0 0;
  border-width: 0 1px 0 0;
}

.rtl .campaign-metadata>div:first-child {
  border-width: 0;
  padding: 0 0 0 10px;
}

.campaign-metadata span:not(.fw-bold) {
  color: #666;
  margin: 0 5px 0 0;
  display: inline-block;
}

.rtl .campaign-metadata span:not(.fw-bold) {
  margin: 0 0 0 5px;
}

.book-btn-bring-it {
  display: block;
  margin: 40px auto 0;
  max-width: fit-content;
}

/* FINDUS */
.findus {
  direction: ltr;
}

.findus .icon-wrapper {
  margin-top: 25px;
}

.findus .icon-wrapper a {
  font-size: 33px;
  margin: 10px;
}

.findus .address-cards {
  width: 100%;
  justify-content: center;
}

.findus .address-cards svg {
  margin-right: 15px;
  color: #fff !important;
  font-size: 25px;
}

.findus .bg-blue {
  background-color: #05c3de;
}

.find-card {
  margin: 24px 0 !important;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  cursor: pointer;
  color: #fff !important;
}

.find-card:hover {
  background-color: #343a40;
  transition: 0.3s ease all;
}

.find-card a {
  width: 100%;
  display: flex;
  justify-content: space-between !important;
  align-items: center;
}

.find-card a h3 {
  font-size: 20px;
  color: #fff !important;
}

.find-card a {
  width: 100%;
  display: flex;
  justify-content: space-between !important;
  align-items: center;
}

.find-card h3 {
  font-weight: bold;
  margin-bottom: 0 !important;
  cursor: pointer;
}

#inline_cal {
  max-width: 375px;
  min-width: 375px;
}

#schedule_car_inline_cal {
  max-width: 375px;
  min-width: 335px;
}

.date-time-select {
  display: flex;
  margin-top: 14px;
  justify-content: center;
}

.header-meun,
.header-meun>button {
  font-size: 19px !important;
}

.rtl .bringItToExperBtn>a {
  font-size: 20px !important;
  font-weight: 600 !important;
}

.rtl .header-meun,
.rtl .header-meun>button {
  font-size: 20px !important;
  font-weight: 600 !important;
}

.about-us-content-wrapper {
  padding-top: 30px;
}

.about-us-heading {
  margin-bottom: 40px;
}

.about-us-content-wrapper p {
  text-align: left;
}

.rtl .about-us-content-wrapper p {
  text-align: right;
}

.about-us-content-wrapper .alj-logo {
  max-width: 420px;
  padding-top: 30px;
}

.about-us-image-wrapper img {
  height: 300px;
  max-width: 100%;
}

/* kaizen page content */
.kaizen-top {
  padding: 50px 0 100px;
}

.kaizen-content-wrapper {
  display: flex;
  align-items: center;
}

.kaizen-content-wrapper p {
  font-size: 20px;
  text-align: left;
}

.rtl .kaizen-content-wrapper p {
  text-align: right;
}

.kaizen-image-wrapper {
  text-align: right;
}

.kaizen-image-wrapper img {
  max-width: 100%;
  border-radius: 8px;
}

/* Required Documents styling */
.requiredDocumentsWrapper {
  max-width: 650px;
  margin: auto;
}

.requiredDropdown {
  display: flex;
  flex-wrap: wrap;
  border-radius: 8px;
  padding: 15px 20px;
  margin: 40px 0 25px;
  background-color: #d1ecf1;
  justify-content: space-between;
}

.requiredDropdownNote {
  color: #0c5460 !important;
  font-weight: 600 !important;
}

.requiredDropdown .requiredDropdown>div {
  margin: 0 10px;
}

.requiredDocumentsWrapper .note-wrapper p {
  font-weight: 600 !important;
}

.requiredDocumentsWrapper .note-wrapper li {
  margin-top: 10px;
  font-size: 14px;
  line-height: 18px;
}

.requiredDocumentsWrapper .note-wrapper li,
.requiredDocumentsWrapper .sub-documents-wrapper ul li {
  list-style: disc;
}

.requiredDocumentsWrapper .sub-documents-wrapper {
  background-color: #d1ecf1;
  padding: 20px !important;
  margin-bottom: 30px;
  border-radius: 8px;
}

.requiredDocumentsWrapper .sub-documents-wrapper li {
  color: #0c5460;
}

.requiredDocumentsWrapper .sub-documents-wrapper .list {
  margin: 15px 0 0;
  padding: 0 20px;
}

.acknowledgedWrapper {
  margin: 25px 0 30px;
}

.acknowledgedWrapper label {
  font-weight: 600;
}

/* Cookie Policy */
.cookieContainer {
  padding: 25px 60px;
  align-items: center !important;
  background-color: #1c2b39 !important;
}

.cookieContainer>div:first-child {
  font-size: 20px;
  line-height: 24px;
  margin: 20px 0 !important;
}

.cookieContainer>div:last-child {
  display: flex;
}

.cookieAcceptBtn {
  font-size: 18px;
  transition: 0.3s;
  color: #fff !important;
  margin: 0 20px 0 0 !important;
  border-radius: 4px !important;
  border: 1px solid #05c3de !important;
  background-color: #05c3de !important;
}

.rtl .cookieAcceptBtn {
  margin: 0 0 0 20px !important;
}

.cookieAcceptBtn:hover {
  transition: 0.3s;
  color: #05c3de !important;
  background-color: transparent !important;
}

.cookieDeclineBtn {
  font-size: 16px;
  transition: 0.3s;
  margin: 0 !important;
  border-radius: 4px !important;
  border: 1px solid #c12a2a !important;
}

.cookieDeclineBtn i {
  transform: translate(-0.5px, 1px);
}

.cookieDeclineBtn:hover {
  color: #c12a2a !important;
  background-color: transparent !important;
}

.cookieContainer a {
  font-weight: 400;
  color: #ffb71b !important;
}

.cookieContainer {
  transition: bottom 2s ease;
  bottom: -155px !important;
}

.cookieContainer.animate {
  bottom: 0 !important;
  transition: bottom 2s ease;
}

.sitemap-wrapper table tr td {
  color: #6f767a;
}

.track-your-progress-main {
  margin: 0 0 50px;
}

.track-your-progress-main .phone-no-wrap {
  margin: 0;
  width: 100% !important;
  justify-content: center;

  .phone-number-codes {
    width: 100%;
  }
}

.track-your-progress-main .enter-details-wrap::before {
  bottom: -50px;
}

.track-your-progress-main .react-tel-input input {
  height: 52px;
}

.sc--img-formats {
  color: #888;
  display: block;
  margin: 5px 0 0;
  font-size: 12px;
}

.route-link {
  color: #05c3de !important;
  cursor: pointer !important;
}

.my-account-link {
  color: #666;
  margin: 10px 0;
  text-align: center;
}

.my-account-link a {
  cursor: pointer;
  color: #05c3de;
}

.rtl .my-account-link a {
  font-size: 16px;
  margin-right: 3px;
}

.rtl .plate-num-ltr {
  direction: ltr;
  text-align: right;
}

.beta-tag {
  display: flex !important;
  align-items: center;
}

.beta-tag span {
  color: #ffb71b;
  cursor: pointer;
  font-size: 10px;
  margin: 0 0 0 5px;
  border-radius: 4px;
  padding: 3px 5px 1px;
  display: inline-block;
  background-color: #fff;
  transform: translateY(-1px);
}

.rtl .beta-tag span {
  font-size: 9px;
  margin: 0 5px 0 0;
  padding: 1px 5px 2px;
}

.beta-tag:hover span {
  color: #fff;
  background-color: #ffb71b;
}

/* Toast cart */
.Toastify__toast-theme--dark,
.Toastify__toast-body>div:last-child {
  font-size: 14px;
  line-height: 16px;
}

.offcanvas-backdrop.show {
  height: 100%;
}

.addon-actions .out-of-stock-btn,
.cart-btn .out-of-stock-btn {
  color: red !important;
}

.btn-no-style {
  background: transparent;
  outline: none;
  border: none;
}

.doc-video video {
  max-width: 100%;
  margin-bottom: 30px;
}

.empty i {
  cursor: not-allowed !important;
  color: #999999 !important;
}

.recent-offers .page-link {
  border-radius: 50%;
  margin: 0 10px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Toast cart */
.Toastify__toast-theme--dark,
.Toastify__toast-body>div:last-child {
  font-size: 14px;
  line-height: 16px;
}

.offcanvas-backdrop.show {
  height: 100%;
}

.addon-actions .out-of-stock-btn,
.cart-btn .out-of-stock-btn {
  color: red !important;
}

.doc-video video {
  max-width: 100%;
  margin-bottom: 30px;
}

.empty i {
  cursor: not-allowed !important;
  color: #999999 !important;
}

.recent-offers .page-link {
  border-radius: 50%;
  margin: 0 10px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.doc-video video {
  max-width: 100%;
  margin-bottom: 30px;
}

/* Appointment notifications modal */
.appointment-notifications-modal {
  overflow: hidden;
}

.appointment-notifications-modal .comments-modal {
  max-height: 80vh;
  overflow-y: auto;
}

.appointment-notifications-modal .comments-modal::-webkit-scrollbar {
  width: 5px;
}

.appointment-notifications-modal .comments-modal::-webkit-scrollbar-thumb {
  border-radius: 12px;
  background-color: #ccc;
}

.appointment-notifications-modal .comments-modal::-webkit-scrollbar-track {
  border-radius: 12px;
  background-color: #f2f2f2;
}

.received-media {
  margin-top: 10px;
}

.received-media span {
  display: block;
  cursor: pointer;
  color: #05c3de;
  margin-top: 2px;
}

.lightbox {
  z-index: 9999999;
  position: relative;
}

/* Vehicles details */
.vehicle-details-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.vehicle-details-wrap>div {
  width: 49%;
  margin-bottom: 10px;
}

.vehicle-details-wrap input:not(.select__input) {
  padding: 14px;
  font-size: 14px;
  border-radius: 8px;
}

.vehicle-details-wrap>div.divider {
  margin: 0;
}

/* fixed bottom nav bar css */
.bottom-nav * {
  /* border: 1px solid red; */
}

.bottom-nav {
  display: flex;
  justify-content: space-evenly;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0.8rem 0;
  background-color: rgba(255, 255, 255);
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
  border-top: 1px solid #ddd;
}

.bottom-tab-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 20%;
}

.bottom-tab-container .bottom-tab-icon {
  color: #a2a2a2;
  font-size: 18px;
  opacity: 1;
}

.bottom-tab-container .bottom-tab-text {
  font-size: 12px !important;
  line-height: 1.2;
  margin-top: 8px;
  color: #a2a2a2;
}

.bottom-tab-container.true * {
  color: #05c3de;
}

.bottom-tab-icon.active {
  color: #05c3de !important;
}

.bottom-tab-text.active {
  color: #05c3de !important;
}

.main-container-hide {
  height: 50vh;
}

.branch-icon {
  transform: translateX(-2px);
}

.rtl .branch-icon {
  transform: translateX(2px);
}

.no-data {
  color: #ccc;
}

/* Blog tags CSS (blogs, services) */
.blogs {
  .tags {
    padding: 25px;
    margin: 40px 0;
    border-radius: 8px;
    background-color: aliceblue;

    h6 {
      font-size: 18px;
    }

    span {
      color: #fff;
      font-size: 14px;
      cursor: pointer;
      transition: 0.3s;
      line-height: 18px;
      padding: 8px 15px;
      text-align: center;
      border-radius: 4px;
      display: inline-block;
      margin: 10px 12px 0 0;
      background-color: #333f48;

      &:hover {
        transition: 0.3s;
        background-color: rgba(51, 63, 72, 0.9);
      }

      &.selected {
        background-color: #05c3de;
      }
    }
  }
}

.rtl .blogs .tags span {
  margin: 10px 0 0 12px;
}

/* CAD style */
.collection-delivery-wrapper {
  max-width: 475px;
  margin: 40px auto 30px;
}

.collection-delivery-wrapper .title_form {
  text-align: start;
}

.collection-delivery-wrapper .toggle {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
}

.collection-delivery-wrapper .toggle-switch {
  display: inline-block;
  background-color: #ddd;
  border-radius: 16px;
  width: 53px;
  height: 28px;
  transform: translateY(-3px);
  position: relative;
  vertical-align: middle;
  transition: background-color 0.25s;
}

.collection-delivery-wrapper .toggle-switch:before,
.collection-delivery-wrapper .toggle-switch:after {
  content: "";
}

.collection-delivery-wrapper .toggle-switch:before {
  display: block;
  background-color: #fff;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 4px;
  left: 4px;
  transition: left 0.25s;
}

.collection-delivery-wrapper .toggle-checkbox:checked+.toggle-switch {
  background-color: #05c3de;
}

.collection-delivery-wrapper .toggle-checkbox:checked+.toggle-switch:before {
  left: 30px;
}

.collection-delivery-wrapper .toggle-checkbox {
  position: absolute;
  visibility: hidden;
}

.collection-delivery-wrapper .toggle-label {
  margin: 0 10px 0 0;
  position: relative;
  top: 2px;
  color: #333f48;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  cursor: pointer;
}

.rtl .collection-delivery-wrapper .toggle-label {
  margin: 0 0 0 10px;
}

.checkBoxWrapper {
  padding-top: 15px;
  max-width: fit-content;
  margin: auto;
  min-width: 475px;
}

.checkBoxWrapper label {
  font-size: 16px;
  color: #222;
}

.AddressWrapper {
  max-width: fit-content;
  margin: auto;
  display: flex;
  justify-content: center;
}

.no-branch-text-wrapper {
  max-width: 475px;
  margin: auto;
}

.schedule-appointment-main .wizard .content .addressInput {
  padding: 15px 55px 15px 15px;
}

.rtl .schedule-appointment-main .wizard .content .addressInput {
  padding: 15px 15px 15px 55px;
}

.addressInputBg:disabled {
  background-color: transparent !important;
}

.btnIcon {
  flex: calc(50% - 10px);
  display: flex;
  height: 56px;
  position: relative;
}

.locationIconBox {
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0px 8px 8px 0px;
  background-color: #05c3de;
  color: #ffffff;
  cursor: pointer;
  margin: 0 0 0 -50px;
  z-index: 1;
}

.rtl .locationIconBox {
  margin: 0 -50px 0 0;
  border-radius: 8px 0px 0px 8px;
}

.cad-price {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 15px;
}

.cad-price span {
  color: #05c3de;
}

.cnd-images {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cnd-images img {
  width: 22%;
}

/* MAP styling */
.searchInputWrap {
  width: 100%;
  height: 70px;
  padding: 12px;
  margin: 12px 0px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0px 0px 50px 10px rgba(30, 38, 64, 0.03);
}

.mapContainer {
  position: relative;
  width: 100%;
}

.mapContainer .inputWrap {
  width: 40%;
  position: absolute;
  z-index: 3;
  top: 60px;
  left: 30%;
  display: flex;
  align-items: center;
}

.mapContainer .inputWrap .inputLeftBtn {
  all: unset;
  width: 100%;
  height: 44px;
  border-radius: 8px;
  background: #fff;
  padding: 0px 10px;
}

.mapContainer .inputWrap .inputLeftBtn &::placeholder {
  color: #6c6c6c;
  font-family: "Geologica";
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
}

.mapContainer .map {
  height: auto !important;
}

@media (max-width: 600px) {
  .mapContainer .inputWrap {
    width: 80%;
    left: 10%;
  }
}

.modal-fullscreen {
  width: 100%;
  min-height: 100vh;
}

.pac-container {
  z-index: 1060;
}

/* Disclaimer modal */
.disclaimer-modal {
  text-align: center;
}

.disclaimer-modal .alert-icon {
  width: 40px;
  height: 40px;
  padding: 20px;
  font-size: 30px;
  color: #05c3de;
  line-height: 40px;
  border-radius: 50%;
  text-align: center;
  margin-bottom: 20px;
  border: 2px solid #05c3de;
}

/* FLIP CARD CSS */
.flip-card {
  background-color: transparent;
  height: 350px;
  perspective: 1000px;
  margin-top: 50px;
}

.flip-card-quote {
  height: 300px;
}

.flip-card-front,
.flip-card-back {
  border-top-left-radius: 30px !important;
  border-top-right-radius: 30px !important;
  border: 1px solid #f1f1f1;
  border-bottom: none;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

/* .flip-card-front {
  background-color: #bbb;
  color: black;
} */
.flip-card-front .image {
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  position: relative;
}

.flip-card-front .image h1 {
  position: absolute;
  bottom: 0;
  font-size: 25px;
  width: 100%;
  color: #fff;
  background-color: #00000075;
  margin: 0;
  padding-top: 10px;
  padding-bottom: 10px;
}

.flip-card-front .details {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.flip-card-front .details bold {
  background-color: #fafafa;
  width: 100%;
  color: #00c0de;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 20px;
  font-weight: 500;
}

.details .btn {
  width: 100%;
  padding: 12px !important;
  border-radius: 0;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  background-color: #ffb71b;
  color: #fff;
  border: none;
  outline: none;
  font-weight: 500;
  font-size: 18px;
  text-transform: uppercase;
}

.flip-card-back {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  transform: rotateY(180deg);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.flip-card-back .name {
  color: #00c0de;
  font-size: 30px;
  font-weight: 500;
}

.flip-card-back .shortDesc {
  text-align: justify;
  font-size: 16px;
  margin-top: 10px;
}

.card-and-terms .estimation-payment-method .radio-label {
  padding: 10px !important;
}

.card-and-terms .estimation-payment-method span {
  margin-right: 10px;
}

.card-and-terms .payment-method {
  font-weight: 500;
  color: #666;
}

.careem-code-wrapper {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.careem-code-wrapper form::after {
  content: "";
  top: 120px;
  bottom: 0;
  right: 0;
  z-index: -1;
  width: 300px;
  position: absolute;
  background-size: cover;
  background-image: url(../images/phone-after.png);
}

.careem-code-wrapper form::before {
  content: "";
  left: 55px;
  z-index: -1;
  width: 318px;
  height: 74px;
  bottom: 0px;
  position: absolute;
  background-size: cover;
  background-image: url(../images/phone-details-after.png);
}

.generate-code-btn {
  text-align: center;
  margin-top: 15px;
}

.careem-code-logo-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
}

.careem-code-logo-wrapper .collab-icon {
  font-size: 60px;
  color: #05c3de;
}

.careem-code-logo-wrapper img {
  width: 220px;
}

.careem-code-logo-wrapper.appointment img {
  width: 180px;
}

.careem-code-logo-wrapper.appointment .collab-icon {
  font-size: 40px;
}

.careem-code-logo-wrapper.appointment .careem-logo {
  margin-bottom: -15px;
}

.careem-code-logo-wrapper.appointment {
  gap: 15px;
}

.careem-terms-wrapper>h6 {
  font-size: 18px !important;
  margin-bottom: 20px !important;
}

.font-size22 {
  font-size: 22px !important;
}

.icon-size {
  font-size: larger;
}

.careem-code-wrapper .careem-inputs .primary-btn-blueRoundRight {
  border-radius: 0 6px 6px 0 !important;
}

.careem-terms h4 {
  text-align: center !important;
  margin-top: 10px;
  margin-bottom: 10px;
}

.careem-terms-wrapper {
  width: fit-content;
  margin: auto;
}

.careem-terms ul li {
  list-style: disc !important;
  font-size: 17px;
}

.rtl .careem-code-wrapper .careem-terms {
  direction: rtl !important;
}

.careem-terms ul li::marker {
  color: #00000087;
}

.careem-terms ul {
  padding-left: 15px;
}

.rtl .careem-terms ul {
  padding-right: 15px;
}

.foundation-day.flip-card {
  height: 375px !important;
}

.foundation-day .flip-card-back .name {
  color: #eae1d2 !important;
}

.foundation-day .expiry-date {
  color: #6C4F47;
  font-weight: 700;
  background-color: #eae1d2 !important;
}

.foundation-day .ribbon-wrapper {
  top: -5px;
}

.foundation-day .ribbon-tag {
  color: #6C4F47;
  font-weight: 700;
  background: #eae1d2 !important;
  left: -6px !important;
  top: 23px !important;
}

.foundation-day .details .btn,
.foundation-day .flip-card-back button {
  color: #CAB364 !important;
  background-color: #6C4F47;
}


.foundation-day .flip-card-front .details .main {
  width: 100%;
  padding-top: 10px;
  padding-bottom: 15px;
  font-size: 16px;
  font-weight: 500;
  color: #912B38;
  display: flex;
  justify-content: space-around;
  height: 100%;
  align-items: center;
  background-color: #eae1d2 !important;
}


.foundation-day .flip-card-front .details {
  height: 74px;

}

.expiry-date {
  position: absolute;
  top: 18px;
  left: 1px;
  font-size: 10px;
  background-color: #05c3de;
  color: white;
  padding: 2px 10px;
  border-radius: 4px 10px 10px 0px;
}

.campaign-container .margin-0 {
  margin: 0 !important;
}

.ribbon-wrapper {
  width: 88px;
  height: 88px;
  overflow: hidden;
  position: absolute;
  top: -6px;
  right: -7px;
  z-index: 1;
}

.ribbon-tag {
  text-align: center;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  position: relative;
  padding: 6px 0;
  left: -8px;
  top: 20px;
  width: 120px;
  font-size: 12px;
  color: #ffffff;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
  text-shadow: rgba(255, 255, 255, 0.5) 0px 1px 0px;
  background: #ffb71b;
}

.rtl .ribbon-tag,
.rtl .foundation-day .ribbon-tag {
  left: 26px !important;
}

.rtl .ribbon-wrapper {
  right: -6px;
}

.ribbon-tag:before,
.ribbon-tag:after {
  content: "";
  border-top: 3px solid #50504f;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  position: absolute;
  bottom: -3px;
}

.ribbon-tag:before {
  left: 0;
}

.ribbon-tag:after {
  right: 0;
}